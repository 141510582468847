import type { FC } from 'react';
import React from 'react';

import {
	ElementName,
	MediaPlacement,
	PreviewBlock,
	SmartLinkSize,
	SmartLinkTheme,
	TitleBlock,
	CustomBlock,
} from '@atlaskit/smart-card';
import { CardSSR as SmartCardSSR } from '@atlaskit/smart-card/ssr';

import { CardSizes } from '../../linkCardsTypes';
import { useTrackedSmartCardState } from '../useTrackedSmartCardState';

import { CardErrorState } from './ErrorStates';
import {
	CommonCardWrapperStyles,
	getTitleBlockStyles,
	EmptyAndSmallCardWrapper as SmallCardWrapper,
	getCustomImageUploadStyles,
} from './cardComponentsStyles';
import { CustomImageUpload } from './CustomImageUpload';
import { getImageTypeToShow } from './getImageTypeToShow';
import type { CommonCardProps } from './commonCardProps';

type SmallCardProps = CommonCardProps & {
	isClickableContainer?: boolean;
};

export const SmallCard: FC<SmallCardProps> = ({
	contentId,
	cardId,
	link,
	title,
	imageSrc,
	isAvatarShown,
	isPublishDateShown,
	cardHeight,
	isInViewMode,
	onCardSucceeded,
	onCardFailed,
	isClickableContainer,
	imageAltText,
	createAnalyticsEvent,
	...analyticsPassThroughProps
}) => {
	const {
		errorCardStatus,
		isNoResult,
		isRestricted,
		isUnauthorized,
		trackAndCategorizeError,
		trackSuccess,
	} = useTrackedSmartCardState(cardId, link, onCardSucceeded, onCardFailed);

	const { showUnsplashImage, showCustomUploadedImage, hasNoImage } = getImageTypeToShow({
		imageSrc,
	});

	if (isRestricted || isNoResult)
		return (
			<CardErrorState
				isRestricted={isRestricted}
				isNoResult={isNoResult}
				link={link}
				cardId={cardId}
				cardSize={CardSizes.SMALL}
				cardHeight={cardHeight}
				isInViewMode={isInViewMode}
				errorStatus={errorCardStatus}
				createAnalyticsEvent={createAnalyticsEvent}
				{...analyticsPassThroughProps}
			/>
		);

	return (
		<CommonCardWrapperStyles isInViewMode={isInViewMode}>
			<SmallCardWrapper
				size={CardSizes.SMALL}
				cardHeight={cardHeight}
				isPublishDateShown={isPublishDateShown}
				hasNoImage={hasNoImage}
			>
				<SmartCardSSR
					id={cardId}
					appearance="inline"
					frameStyle="show"
					platform="web"
					ui={{
						theme: SmartLinkTheme.Black,
						clickableContainer: isClickableContainer ?? isInViewMode,
						size: SmartLinkSize.Large,
					}}
					url={link}
					onResolve={trackSuccess}
					onError={trackAndCategorizeError}
				>
					{showUnsplashImage && (
						<PreviewBlock
							ignoreContainerPadding
							placement={MediaPlacement.Left}
							overrideUrl={imageSrc}
						/>
					)}
					{showCustomUploadedImage && (
						<CustomBlock overrideCss={getCustomImageUploadStyles(MediaPlacement.Left) as any}>
							<CustomImageUpload
								imageContentId={contentId}
								imageSrc={imageSrc}
								imageAltText={imageAltText}
							/>
						</CustomBlock>
					)}
					<TitleBlock
						hideIcon
						text={title}
						maxLines={1}
						anchorTarget="_self"
						overrideCss={
							getTitleBlockStyles({
								isSmallCard: true,
								isUnauthorized,
							}) as any
						}
						{...(!!isAvatarShown && { metadata: [{ name: ElementName.AuthorGroup }] })}
						{...(!!isPublishDateShown && { subtitle: [{ name: ElementName.ModifiedOn }] })}
					/>
				</SmartCardSSR>
			</SmallCardWrapper>
		</CommonCardWrapperStyles>
	);
};
