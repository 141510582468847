import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Image from '@atlaskit/image';
import EmptyState from '@atlaskit/empty-state';
import { Inline, xcss } from '@atlaskit/primitives';

import dynamicCardsStarterImage from '../../assets/DynamicCardsStarter.svg';

const i18n = defineMessages({
	header: {
		id: 'custom-sites-extensions.link-cards-content.card-components.dynamic-starter-empty-state.header',
		defaultMessage: 'Get started with filters for dynamic cards',
		description: 'Body header text for dynamic content modal dialog',
	},
	description: {
		id: 'custom-sites-extensions.link-cards-content.card-components.dynamic-empty-starter-state.description',
		defaultMessage: 'Define your rules for automatically generated cards.',
		description: 'Body subtext for dynamic content modal dialog',
	},
});

const imageStyles = xcss({
	paddingBottom: 'space.200',
	flexDirection: 'column',
	alignItems: 'center',
});

export const DynamicStarterEmptyState = () => {
	const intl = useIntl();

	return (
		<EmptyState
			header={intl.formatMessage(i18n.header)}
			description={intl.formatMessage(i18n.description)}
			headingLevel={3}
			renderImage={() => (
				<Inline xcss={imageStyles}>
					<Image src={dynamicCardsStarterImage} alt="" testId="dynamic-cards-example-image" />
				</Inline>
			)}
		/>
	);
};
