import { useEffect, useState } from 'react';

import type { Card, LinkCardsParameters } from '../linkCardsTypes';
import { RenderVariant } from '../linkCardsTypes';

import { useSearchPageResults } from './useSearchPageResults';

export const useCards = (parameters: LinkCardsParameters) => {
	const { cards, cardCount, filters, renderVariant } = parameters;
	const [dynamicCards, setDynamicCards] = useState<Card[]>([]);

	const { renderDynamicCards } = useSearchPageResults(setDynamicCards, cardCount, filters);

	useEffect(() => {
		if (renderVariant === RenderVariant.DYNAMIC) {
			if (filters) {
				void renderDynamicCards();
			} else {
				setDynamicCards([]);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cardCount, filters, renderVariant]);

	const isDynamicCards = renderVariant === RenderVariant.DYNAMIC;

	if (!isDynamicCards) {
		return cards;
	}

	return dynamicCards;
};
