import type {
	EntityATI,
	SearchPageQueryVariables,
	SearchConfluencePartial,
} from '@atlassian/dynamic-cards-search-client';
import { searchPageQuery, PAGE_ATI, BLOGPOST_ATI } from '@atlassian/dynamic-cards-search-client';
import {
	getConfluenceDateOptionValues,
	getConfluenceDateOptionFromCustomDates,
} from '@atlassian/dynamic-cards-search-page';

import { useSearchSessionId } from '@confluence/search-session';
import { useSessionData } from '@confluence/session-data';

import type { Card, Filters } from '../linkCardsTypes';
import {
	CARD_FIELDS,
	createNewCardWithDefaultParameters,
} from '../../shared-components/ConfigPanel';
import { propertyToId, CoverPictureIdPropertyKey } from '../../shared-components/UnsplashSearch';

// TODO: create utils file to group below methods to sanitize and convert filters into searchPageQuery variables
const getFilterValue = (filter?: string) => {
	return filter?.split(',') || [];
};

const getFilterDateRange = (filters: Filters) => {
	const { lastModified } = filters;
	if (lastModified && lastModified !== 'custom') {
		return [getConfluenceDateOptionValues(lastModified)];
	} else if (lastModified === 'custom') {
		if (filters?.from && filters?.to) {
			return [getConfluenceDateOptionFromCustomDates(filters.from, filters.to)];
		}
	}
	return [];
};

// TODO: handle the accuracy of getEntities in https://hello.jira.atlassian.cloud/browse/COMPHUB-2224
const getEntities = (type?: string) => {
	if (type === 'page') {
		return [PAGE_ATI];
	} else if (type === 'blogpost') {
		return [BLOGPOST_ATI];
	}
	return [PAGE_ATI, BLOGPOST_ATI];
};

const getConfluenceFilters = (filters: Filters) => {
	const { spaces, owners, labels, contributors, ancestor, verified } = filters;

	const range = getFilterDateRange(filters);

	return {
		spacesFilter: getFilterValue(spaces),
		isVerified: verified || undefined,
		contributorsFilter: getFilterValue(contributors),
		ancestorIdsFilter: getFilterValue(ancestor),
		range,
		owners: getFilterValue(owners),
		labelsFilter: getFilterValue(labels),
	};
};

const getSearchPageQueryVariables = (
	cardCount: number,
	filters: Filters,
	cloudId: string,
	searchSessionId: string | null | undefined,
) => {
	const entities = getEntities(filters.type) as EntityATI[];

	return {
		query: '',
		cloudIdARI: `ari:cloud:confluence::site/${cloudId}`,
		tenantId: cloudId,
		limit: cardCount,
		entities,
		commonFilters: {},
		confluenceFilters: getConfluenceFilters(filters),
		jiraFilters: {
			issueFilter: {
				projectARIs: [],
				assigneeARIs: [],
			},
		},
		thirdPartyFilters: {
			titleMatchOnly: false,
			subtypes: [],
		},
		queryVersion: 1,
		searchSessionId: searchSessionId ?? '',
		sort: [],
	};
};

export const useSearchPageResults = (
	setDynamicCards: (dynamicCards: Card[]) => void,
	cardCount?: number,
	filters?: Filters,
) => {
	cardCount ||= 3;
	filters ||= {};

	const { cloudId } = useSessionData();
	const [{ searchSessionId }] = useSearchSessionId();

	const variables = getSearchPageQueryVariables(
		cardCount,
		filters,
		cloudId,
		searchSessionId,
	) satisfies SearchPageQueryVariables;

	const getQueryData = async () => await searchPageQuery({ variables });

	const renderDynamicCards = async () => {
		let trimmedResults = (await getQueryData()).data?.search.results.edges.map(
			(edge) => edge.node as SearchConfluencePartial,
		);

		trimmedResults = trimmedResults?.slice(0, Math.min(cardCount, trimmedResults.length));

		const dynamicCards =
			trimmedResults?.map((result) => {
				const { title, description, url, id, confluenceEntity } = result;
				const newCard = createNewCardWithDefaultParameters();

				newCard[CARD_FIELDS.LINK] = url;
				newCard[CARD_FIELDS.TITLE] = title;
				newCard[CARD_FIELDS.DESCRIPTION] = description;
				newCard[CARD_FIELDS.CONFLUENCE_PAGE_ID] = id.split('/')[1];

				if (confluenceEntity) {
					const { subtype, properties } = confluenceEntity;

					const isLivePage = subtype === 'live';
					let imageProperty;

					if (isLivePage) {
						imageProperty = properties?.find(
							(image) => image?.key === CoverPictureIdPropertyKey.DRAFT,
						);
					} else {
						imageProperty = properties?.find(
							(image) => image?.key === CoverPictureIdPropertyKey.PUBLISHED,
						);
						if (!imageProperty) {
							imageProperty = properties?.find(
								(image) => image?.key === CoverPictureIdPropertyKey.DRAFT,
							);
						}
					}

					newCard[CARD_FIELDS.IMAGE_SRC] = propertyToId(imageProperty)?.value?.id;
				}

				return newCard;
			}) || [];

		return setDynamicCards(dynamicCards);
	};

	return { getQueryData, renderDynamicCards };
};
